<template>
  <v-row>
    <v-col>
      <v-combobox
          :label="$t('vendor')"
          v-model="selectedVendor"
          :search-input.sync="searchVendor"
          :items="vendors"
          :loading="vendorLoading"
          item-value="id"
          item-text="name"
          clearable
          :readonly="readonly"
          :disabled="disabled"
          no-filter
          hide-details
          hide-no-data
      >
        <template #item="{attrs, select, selected, item}">
          {{ item.name }}
        </template>
      </v-combobox>
    </v-col>
    <v-col>
      <v-autocomplete
          :label="$t('model')"
          v-model="data"
          :search-input.sync="searchModel"
          :items="models"
          :loading="loading"
          v-on="$listeners"
          @input="$refs.input.blur()"
          :rules="rules"
          item-value="id"
          :solo="solo"
          :clearable="clearable"
          :append-icon="appendIcon"
          :readonly="readonly"
          :disabled="disabled"
          :placeholder="placeholder"
          no-filter
          :multiple="multiple"
          hide-details
          hide-no-data
      >
        <template #selection="{attrs, select, selected, item}">
          {{ item.vendorLabel }} / {{ item.modelLabel }}
        </template>
        <template #item="{item, on, attrs}">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ item.vendorLabel }}
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ item.modelLabel }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>

  </v-row>
</template>

<script>
import ChargingPointRepository from "@repository/ChargingPointRepository";

export default {
  data() {
    return {
      loading: false,
      vendorLoading: false,
      searchModel: null,
      searchVendor: null,
      selectedVendor: null,
      data: this.value,
      vendors: [],
      models: [],
    }
  },

  model: {
    prop: 'value',
  },

  props: {
    value: [String, Number, Array],
    rules: Array,
    multiple: Boolean,
    placeholder: String,
    disabled: Boolean,
    readonly: Boolean,
    appendIcon: String,
    solo: Boolean,
    clearable: Boolean,
    modelSearch: String,
    vendorSearch: String,
  },

  mounted() {
    this.loadVendors()
    this.loadData()
  },

  destroyed() {

  },

  watch: {
    data(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.data = val
    },

    searchVendor(val) {
      this.$emit('update:vendorSearch', val)
      this.loadVendors()
    },

    searchModel(val) {
      this.loadData()
    },

    selectedVendor(val) {
      this.loadData()
    },

  },


  methods: {
    loadVendors() {
      this.vendorLoading = true
      ChargingPointRepository.chargingPointTypeVendors(this.searchVendor).then(data => {
        this.vendors = data
        this.vendorLoading = false
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.vendorLoading = false
      })

    },

    loadData() {

      this.loading = true
      ChargingPointRepository.chargingPointTypes({
        searchText: this.searchModel,
        vendor: this.selectedVendor?.name ?? this.searchVendor,
        usedOnly: true,
      }).then(data => {
        this.models = data.items
        this.loading = false
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
      })
    },

  }
}
</script>

<style lang="scss">
  .cpt-select-item {
    position: absolute;
    border-top: 1px solid #ccc;
    & > div {
      color: #575757;
      font-size: 10px;
    }
  }

</style>
