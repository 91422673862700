<template>
  <div class="charging-point-list">
    <v-row>
      <v-col md="9">
        <v-card>
          <v-card-title>
            {{ $t("charging-point.search-charging-point") }}
          </v-card-title>
          <v-card-text>

            <v-form @submit.prevent="search" class="form-filters">
              <v-row dense>
                <v-col>
                  <v-text-field
                      v-model="filters.search"
                      :placeholder="$t('charging-point.search-placeholder')"
                      prepend-inner-icon="mdi-magnify"
                      clearable
                      hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col v-if="!chargingPointTypeId">
                  <charging-point-type-select
                      :label="$t('vendor') + ' / ' + $t('model')"
                      v-model="filters.chargingPointTypeId"
                      :vendor-search.sync="filters.vendor"
                      clearable
                      hide-details
                  />
                </v-col>
                <v-col :cols="chargingPointTypeId ? 12 : 4">
                  <v-text-field
                      :label="$tc('location.location')"
                      v-model="filters.location"
                      clearable
                      hide-details
                  />
                </v-col>
              </v-row>
              <v-row dense>

                <v-col>
                  <v-autocomplete
                      :label="$t('status')"
                      v-model="filters.status"
                      clearable
                      multiple
                      small-chips
                      deletable-chips
                      hide-details
                      :items=status_data
                  >
                  </v-autocomplete>
                </v-col>
                <v-col md="5">
                  <v-select
                      :label="$t('customer.home-charging-point')"
                      clearable
                      hide-details
                      :items="[
                      {text: $t('yes'), value: true},
                      {text: $t('no'), value: false},
                  ]"
                      v-model="filters.homeChargingPoint"
                  />
                </v-col>
                <v-col cols="auto" v-if="currentUser.isZE">
                  <v-checkbox
                      :label="$t('archived')"
                      v-model="filters.archived"
                      hide-details
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="auto" v-if="currentUser.isZE">
                  <v-checkbox
                      :label="$t('enums.charging-point-status.NOT_INSTALLED')"
                      v-model="filters.notInstalled"
                      hide-details
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-right">
                  <v-btn type="submit" color="primary" small>{{ $t('search') }}</v-btn>
                </v-col>
              </v-row>

            </v-form>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-row>
          <v-col class="d-flex">
            <div class="map">
              <template>
                <Map ref="map" :items="markers" :selectedItem="selectedItem"></Map>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
          class="cp-list"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
        >

          <template v-slot:body="{ items }">
            <tbody>
              <tr
                  v-for="item in items"
                  :key="item.id"
                  @mouseenter="onRowOver(item)" @mouseleave="onRowOut(item)"  @click="onRowClick(item)"
                  :class="{selected: selectedItem.id === item.id}"
              >
                <td class="text-start">
                  <router-link class="click" :to="routeTo(item)">
                    <span class="identifier">{{ item.identifier }} / {{ item.evses.map(e => e.evseId).join(', ') }}</span>
                  </router-link>
                </td>
                <td class="text-start">
                  <span class="firmwareversion"> {{ item.firmwareVersion}}</span>
                </td>
                <td class="text-start">
                  <span class="model">{{ item.chargingPointType.vendorLabel }} / {{ item.chargingPointType.modelLabel }}</span>
                </td>
                <td class="text-start">
                  <router-link class="click" :to="routeTo(item)">
                    <span class="name" v-if="item.name">{{ item.name }}</span>
                    <span v-else>{{  $t('not-provided') }}</span>
                  </router-link>
                </td>
<!--                <td class="text-start">-->
<!--                  <span>{{ item.customer?.firstname }} <br>{{ item.customer?.lastname }}</span>-->
<!--                </td>-->
                <td class="text-center">
                  <div class="mt-1">
                    <img src="@images/locate-cp.png" style="cursor: pointer;" width="25" @click="onIconClick(item)" @mouseenter="onIconOver(item)" @mouseleave="onIconOut(item)">
                  </div>
                  <div style="font-weight: 400;" v-if="item.location">{{ item.location.name }}</div>
                  <div style="font-weight: 100;">
                    {{ locationString(item) }}
                  </div>
                </td>
                <td class="text-start">
                  <div class="status-item" v-for="evse in item.evses" :key="evse.id" >
                    <chip-status :value="evse.status" :charging-point-id="item.id" :connector-ids="evse.connectors.map(c => c.connectorId)" /><br />
                    {{ evse.physicalReference }}
                  </div>
                </td>
                <td class="text-start">
                  <span>{{ item.lastConnection | formatDate }}</span>
                </td>
                <td class="text-start">
                  <span>{{ item.lastUsage | formatDate }}</span>
                </td>
              </tr>
            </tbody>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import Map from '@blocks/Map'
import ChargingPointRepository from "@repository/ChargingPointRepository";
import ChargingPointTypeSelect from "@blocks/Select/ChargingPointTypeSelect.vue";


export default {
  components: {
    ChargingPointTypeSelect,
    Map,
  },

  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      selectedItem: {},
      chargingPointTypes: [],
      options: null,
      markers: [],

      status_data: [
        {text: this.$t('enums.evse-status.AVAILABLE'), value: 'AVAILABLE'},
        {text: this.$t('enums.evse-status.BLOCKED'), value: 'BLOCKED'},
        {text: this.$t('enums.evse-status.CHARGING'), value: 'CHARGING'},
        // {text: this.$t('enums.evse-status.INOPERATIVE'), value: 'INOPERATIVE'},
        {text: this.$t('enums.evse-status.OFFLINE'), value: 'OFFLINE'},
        {text: this.$t('enums.evse-status.OUTOFORDER'), value: 'OUTOFORDER'},
        // {text: this.$t('enums.evse-status.PLANNED'), value: 'PLANNED'},
        // {text: this.$t('enums.evse-status.REMOVED'), value: 'REMOVED'},
        // {text: this.$t('enums.evse-status.RESERVED'), value: 'RESERVED'},
        // {text: this.$t('enums.evse-status.UNKNOWN'), value: 'UNKNOWN'},
      ],
      filters: {
        search:'',
        chargingPointTypeId: null,
        vendor: null,
        status: [],
        location: null,
        archived: false,
        homeChargingPoint: null,
        notInstalled: false,
      }
    }
  },

  props: {
    organizationId: String,
    chargingPointTypeId: String,
    locationId: String,
  },

  mounted() {
    this.search()
  },


  watch: {
    // filters: {
    //   handler(val) {
    //     this.search()
    //   },
    //   deep: true
    // },

    // 'filters.status': function (val) {
    //   this.search()
    // },
    //
    // 'filters.model': function (val) {
    //   this.search()
    // },
    //
    // 'filters.chargingPointTypeId': function (val) {
    //   this.search()
    // },

    options(val) {
      this.searchData()
    },

  },

  computed: {
    headers() {
      return [
        {text: this.$t("number-imei") + '/' + this.$t("evse-id"), value: 'identifier'},
        {text: this.$t("charging-point.firmware-version"), value: 'firmwareVersion'},
        {text: this.$t("vendor") + ' / ' + this.$t("model"), value: 'chargingPointType.modelLabel'},
        {text: this.$t("name"), value: 'name'},
        // {text: this.$tc("customer.customer"), value: 'customer.lastname'},
        {text: this.$tc("location.location"), value: 'location.address.city'},
        {text: this.$t("status"), value: 'status'},
        {text: this.$t("last-connection"), value: 'lastConnection'},
        {text: this.$t("last-usage"), value: 'lastUsage'},
      ]
    }
  },

  methods: {

    loadMarkers() {
      let query = this.filters.search
      let status = this.filters.status
      let chargingPointTypeId = this.chargingPointTypeId ??  this.filters.chargingPointTypeId
      let location = this.filters.location
      let archived = this.filters.archived
      let notInstalled = this.filters.notInstalled
      let homeChargingPoint = this.filters.homeChargingPoint
      ChargingPointRepository.markers({
        query,
        status,
        chargingPointTypeId,
        location,
        notInstalled,
        vendor: this.filters.vendor,
        organizationId: this.organizationId,
        locationId: this.locationId,
        archived,
        homeChargingPoint,
      }).then(result => {
        this.markers = result
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
      })
    },

    search() {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.searchData()
        this.loadMarkers()
      }
    },

    searchData() {
      this.selectedItem = {}
      let options = this.options
      this.loading = true
      let query = this.filters.search
      let status = this.filters.status
      let archived = this.filters.archived
      let notInstalled = this.filters.notInstalled
      let homeChargingPoint = this.filters.homeChargingPoint
      let chargingPointTypeId = this.chargingPointTypeId ??  this.filters.chargingPointTypeId
      let location = this.filters.location
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let sortBy = options.sortBy.length > 0 ? options.sortBy[0] : 'createdAt'
      let sortOrder = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'


      if(sortBy === 'status') {
        sortBy = 'evses.status'
      }

      ChargingPointRepository.search({
        query,
        status,
        chargingPointTypeId,
        location,
        offset,
        limit,
        sortBy,
        sortOrder,
        archived,
        notInstalled,
        homeChargingPoint,
        vendor: this.filters.vendor,
        organizationId: this.organizationId,
        locationId: this.locationId,
      }).then(result => {
        this.total = result.total
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },

    locationString(item) {

      let str = []
      let address = item.location?.address
      if(address) {
        str.push(address.city)
      }

      return str.join(' ')
    },


    onRowClick(item) {
      // this.selectedItem = {
      //   id: item.id,
      //   position: item.location?.coordinates
      // }
    },

    onRowOver(item) {
      // this.selectedItem = {
      //   id: item.id,
      //   position: item.location?.coordinates
      // }
      // this.$refs.map.fitBounds()
    },

    onRowOut(item) {
      // this.$refs.map.fitBounds()
    },

    onIconClick(item) {
      this.$refs.map.flyToItem(item.location?.coordinates)
    },


    onIconOver(item) {
      if(item.location?.coordinates) {
        this.selectedItem = {
          id: item.id,
          position: item.location?.coordinates
        }
      } else {
        this.selectedItem = null
      }
    },

    onIconOut(item) {
    //  this.$refs.map.fitBounds()
    },

    routeTo(item) {
      // console.log(item.status)
      if (item.status === 'NOT_INSTALLED') {
        return {name: 'charging-points.add', query: {imei: item.identifier}}
      } else {
        return {name: 'charging-points.detail', params: {id: item.id}}
      }

    }
  }
}
</script>

<style lang="scss">
.charging-point-list {
  .v-card__title {
    padding-bottom: 7px;
  }
  .form-filters {
    .v-select {
      .v-input__control {
        min-height: 36px;
      }
    }
  }
  .map {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: unset;

    .th-map {
      border-left: 1px solid #ccc;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #5F6569;
      height:30px;
      margin-bottom: 20px;

    }

    .map-block {

      border: 3px solid #E53C22;
      border-radius: 0 10px 10px 0;
      overflow: hidden;
      min-height: 300px;
      position: sticky;
      top: 30px;
    }
  }

  .v-data-table {
    .v-data-table__wrapper {
      overflow: visible;
    }
    tr.selected {
      td {
        background: white;
        //&:last-child {
        //  position: relative;
        //  &:before {
        //    top: 50%;
        //    transform: translateY(-50%);
        //    z-index: 1;
        //    content: '';
        //    width: 15px;
        //    height: 30px;
        //    position: absolute;
        //    right: -15px;
        //    background: #E53C22;
        //    border-radius: 0 30px 30px 0;
        //
        //  }
        //  &:after {
        //    top: 50%;
        //    transform: translateY(calc(-50% - 2px));
        //    z-index: 2;
        //    content: '>';
        //    color:#E53C22;
        //    font-size: 20px;
        //    padding-right: 5px;
        //    line-height: 20px;
        //    width: 15px;
        //    height: 26px;
        //    position: absolute;
        //    margin-top: 2px;
        //    margin-bottom: 2px;
        //    margin-right: 2px;
        //    right: -15px;
        //    background: white;
        //    border-radius: 0 30px 30px 0;
        //
        //  }
        //}
      }
    }
    td {
      height: 90px !important;
      .number {
        color: #E53C22;
        font-weight: bold;
      }
      .identifier {
        color: #71A723;
        font-weight: bold;
      }
      .click{
        text-decoration: none;
        color: #71A723;
      }
      .name {
        color: #E53C22;
        font-weight: bold;
        text-transform: uppercase;
      }
      .status-item {
        display: inline-block;
        margin: 3px;
        font-weight: 500;
      }
    }
  }

}





</style>
